import React, { useState } from 'react';
import styles from './FAQ.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Collapse } from 'react-bootstrap';
import { BsPlusCircle, BsDashCircle } from 'react-icons/bs';

const faqData = [
  {
    question: "1. What is Catch The AI?",
    answer: (
      <>
        "<strong>Catch The AI</strong>" is an innovative platform designed to assist users in discerning between content generated by humans and that created using artificial intelligence AI technologies. It serves as a tool for verifying the authenticity of digital content, including text, images, and audio, by leveraging advanced algorithms and machine learning techniques.
      </>
    )
  }, 
  {
    question: "2. How does Catch The AI detect AI-generated content?",
    answer: (
      <>
        "Our platform utilizes advanced algorithms and deep learning techniques to analyze multimedia content. We focus on three primary detection models:
        <br/><br/>
        <strong>1-AI Image Generation Detection:</strong> Identifies and distinguishes AI-generated images from human-created ones.
        <br/>
        <strong>2-AI Text Generation Detection:</strong> Recognizes text generated by AI and determines its authorship.
        <br/>
        <strong>3-AI-Generated Audio Detection:</strong> Differentiates between AI-generated audio and authentic human-recorded audio."
      </>
    )
  },
  {
    question: "3. Is Catch The AI free to use?",
    answer: (
      <>
        "<strong>Yes</strong>, Catch The AI has a free version available for users. However, for more advanced features and increased usage, there are subscription plans that users can opt for. The free version allows basic usage, while the subscription plans offer additional benefits and functionalities."
      </>
    )
  },
 
  {
    question: "4. How can I reach out to the support team for assistance?",
    answer: (
      <>
        "If you need assistance or have any questions, you can reach out to our support team through the following channels:
        <br/><br/>
        <strong>1- Email:</strong> Send us an email at <a href="mailto:support@catchtheai.com">support@catchtheai.com</a> for any inquiries or technical support.
        <br/>
        <strong>2- Contact Form:</strong> Visit the <a href="/ContactUs">Contact Us</a> page on our website and fill out the contact form with your details and query. Our team will get back to you as soon as possible.
        <br/>
        <strong>3- Phone:</strong> Call our support hotline at <a href="tel:010011012">+1-800-123-4567</a> for direct support (available Monday to Friday, 9 AM to 5 PM EST)."
      </>
    )
  },
  {
    question: "5. How does Catch the AI determine if content is AI-generated?",
    answer: (
      <>
        "Catch the AI determines if content is AI-generated by using advanced machine learning algorithms and natural language processing (NLP) techniques. These tools analyze the text for patterns, styles, and nuances that are characteristic of AI-generated content, such as <strong>repetitive phrases</strong>, <strong>unusual syntax</strong>, or a lack of nuanced understanding. The system is continuously updated with new data to stay effective against evolving AI models."
      </>
    )
  },
  {
    question: "6. Can Catch the AI detect subtle signs of AI-generated content that might be missed by manual inspection?",
    answer: (
      <>
        "Yes, Catch The AI employs advanced algorithms and deep learning techniques specifically designed to detect subtle signs of AI-generated content. While manual inspection may overlook nuanced indicators, our platform can identify patterns and characteristics inherent to AI-generated media, such as <strong>artifacts</strong>, <strong>inconsistencies</strong>, or <strong>statistical anomalies</strong> that are typically not present in human-created content. This capability allows Catch The AI to provide more accurate and reliable detection of AI-generated content, ensuring greater transparency and trust in multimedia information."
      </>
    )
  },
  {
    question: "7. Is Catch the AI capable of distinguishing between different types of AI models used to generate content?",
    answer: (
      <>
        "Yes, Catch The AI is designed to not only detect AI-generated content but also to distinguish between different types of AI models used to generate that content. Our advanced algorithms can identify specific patterns and characteristics associated with various AI models, such as <strong>GANs</strong>, <strong>transformers</strong>, and other deep learning techniques, enabling more precise detection and classification."
      </>
    )
  },

  {
    question: "8. What industries can benefit from using Catch The AI?",
    answer: (
      <>
        "Catch The AI can benefit industries such as <strong>media and entertainment</strong>, <strong>journalism</strong>, <strong>advertising</strong>, <strong>education</strong>, and <strong>law enforcement</strong>. It helps these sectors maintain authenticity and trustworthiness in their multimedia content."
      </>
    )
  },
  {
    question: "9.How often is the detection algorithm updated?",
    answer: (
      <>
        "The detection algorithm is regularly updated to keep up with the latest developments in AI technology. This ensures that Catch The AI remains effective against new AI models and techniques, providing users with accurate and reliable results."
      </>
    )
  },
  {
    question: "10. What are the Terms of Service for using Catch The AI?",
    answer: (
      <>
      "By using Catch The AI, you agree to abide by our <strong>Terms of Service</strong>. 
      These terms govern your use of our platform, including guidelines for acceptable use, 
      limitations of liability, and other important provisions. 
      It's important to review and understand these terms before using our services. 
      For more information, please visit our  <a href="/terms-of-service">Terms of Service</a>.
      "
      </>
    )
  },

];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className={styles.faqContainer}>
      <h2 className={styles.title}>Frequently Asked Questions</h2>
      <div className={styles.accordion}>
        {faqData.map((faq, index) => (
          <div key={index} className={styles.faqItem}>
            <div className={styles.question} onClick={() => handleToggle(index)}>
              {faq.question}
              {openIndex === index ? <BsDashCircle className={styles.icon} /> : <BsPlusCircle className={styles.icon} />}
            </div>
            <Collapse in={openIndex === index}>
              <div className={styles.answer}>
                {faq.answer}
              </div>
            </Collapse>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
